import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isBefore } from 'date-fns';

import { showFlag } from './Flag';
import PermissionAwareDisplay from './PermissionAwareDisplay';
import PermissionAwareText from './PermissionAwareText';

import type {
    BudgetPriceHypothesis as BudgetPriceHypothesisEntity,
    BudgetPriceHypothesisFormData,
} from '../slices/budgetPriceHypothesisSlice';
import { selectError, updateBudgetPriceHypothesis } from '../slices/budgetPriceHypothesisSlice';
import type { Permission } from '../slices/authSlice';

import { colors } from '../constants/colors';
import { useAppDispatch } from '../store';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BudgetPriceHypothesisContainer = styled.div`
    width: '100%';
    display: 'flex';
    margin: '0 0 0.5rem 0';
`;

const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
`;

const Separator = styled.div`
    width: 0.1rem;
    background-color: ${colors.neutral.N75};
    padding: 2rem 0 0 0;
    height: 70%;
`;

const YearContainer = styled.div`
    display: flex;
    margin: 0 1rem 0 0;
`;

const IncreasingFactorContainer = styled.div`
    display: flex;
    padding: 0 2rem 0 0;
`;

type Props = {
    budgetPriceHypothesis: BudgetPriceHypothesisEntity;
    isEditMode: boolean;
    permissionNeeded: Permission[];
    operationId: number;
    hasRightToEdit: boolean;
    separator: boolean;
    increasingFactorPlaceHolder?: number | null;
};

const BudgetPriceHypothesis: React.FC<Props> = ({
    budgetPriceHypothesis,
    isEditMode,
    permissionNeeded,
    operationId,
    hasRightToEdit,
    separator,
    increasingFactorPlaceHolder,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const serverError = useSelector(selectError);

    const priceHypothesisSchema = Yup.object().shape({
        id: Yup.number().required(t('errors.required')),
        year: Yup.number().required(t('errors.required')),
        increasingFactor: Yup.number().test(
            'increasingFactor',
            t('budget.priceHypothesisIncreasingFactorMustBeBetween0And100'),
            (value) => {
                if (value) {
                    return value >= 0 && value <= 100;
                } else {
                    return false;
                }
            },
        ),
    });

    const priceHypothesisIsPassed = isBefore(
        new Date(`${budgetPriceHypothesis.year}-12-31`),
        new Date(),
    );

    useEffect(() => {
        if (isUpdating) {
            if (serverError) {
                showFlag('error', t('errors.error'), t(serverError));
                setIsUpdating(false);
            } else {
                setIsUpdating(false);
            }
        }
    }, [isUpdating, serverError, t]);

    return (
        <Container>
            <Formik
                initialValues={{
                    ...budgetPriceHypothesis,
                    increasingFactor:
                        budgetPriceHypothesis.increasingFactor !== null
                            ? (Number(budgetPriceHypothesis.increasingFactor) * 100).toFixed(2)
                            : '',
                }}
                validationSchema={priceHypothesisSchema}
                onSubmit={(values: BudgetPriceHypothesisFormData, actions) => {
                    void dispatch(
                        updateBudgetPriceHypothesis({
                            budgetPriceHypothesis: {
                                ...values,
                                increasingFactor:
                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- necessary
                                    values.increasingFactor !== null
                                        ? (Number(values.increasingFactor) / 100).toString()
                                        : '',
                            },
                            operationId,
                            budgetId: budgetPriceHypothesis.budgetId,
                        }),
                    );
                    setIsUpdating(true);
                    actions.setSubmitting(false);
                }}
                enableReinitialize
            >
                {(props: FormikProps<BudgetPriceHypothesisFormData>) => (
                    <BudgetPriceHypothesisContainer>
                        <YearContainer>
                            <PermissionAwareDisplay
                                label={t('general.year')}
                                value={budgetPriceHypothesis.year}
                                style={{
                                    marginTop: '1.5rem',
                                    marginBottom: '0',
                                    whiteSpace: 'nowrap',
                                }}
                            />
                        </YearContainer>
                        <IncreasingFactorContainer>
                            <PermissionAwareText
                                displayStyle={{
                                    margin: '1.5rem 0 0 0',
                                    whiteSpace: 'nowrap',
                                }}
                                marginTop="1.5rem"
                                marginBottom="0"
                                disabled={priceHypothesisIsPassed}
                                editable={isEditMode && hasRightToEdit}
                                permissionsRequired={permissionNeeded}
                                label={t('budget.increasingFactor')}
                                name="increasingFactor"
                                type="text"
                                placeholder={
                                    increasingFactorPlaceHolder !== null &&
                                    increasingFactorPlaceHolder !== undefined
                                        ? (Number(increasingFactorPlaceHolder) * 100).toFixed(2)
                                        : '0'
                                }
                                data-testid={`increasingFactor-${budgetPriceHypothesis.id}`}
                                errorTestId={`errorIncreasingFactor-${budgetPriceHypothesis.id}`}
                                onBlur={(e) => {
                                    if (props.dirty) {
                                        props.handleSubmit();
                                    }
                                }}
                                numberType="percentage"
                                value={
                                    priceHypothesisIsPassed
                                        ? t('budget.realValue')
                                        : budgetPriceHypothesis.increasingFactor?.toString()
                                }
                                whiteBackground
                                displayPlaceHolderIfNull
                            />
                        </IncreasingFactorContainer>
                    </BudgetPriceHypothesisContainer>
                )}
            </Formik>
            {separator && (
                <SeparatorContainer>
                    <Separator />
                </SeparatorContainer>
            )}
        </Container>
    );
};

export default BudgetPriceHypothesis;
