import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { styles } from '../constants/styles';
import { colors } from '../constants/colors';
import Text from './Text';

import type { RenderCellMapType } from './TableRow';
import TableRow from './TableRow';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import Icon from './Icon';

const Wrapper = styled.div<WrapperProps>`
    overflow-x: auto;
    box-sizing: border-box;
    min-width: 100%;
    width: calc(100vw ${({ windowWidth }) => (windowWidth > 1025 ? '- 19rem' : '- 10rem')});
    max-width: fit-content;
    height: ${({ tableHeight }) => tableHeight && tableHeight};
    margin-bottom: 1rem;
    border-bottom: ${({ fullBorder }) => fullBorder && `0.05rem solid ${colors.neutral.N200};`};
`;

export const Grid = styled.div<GridProps>`
    display: -ms-grid;
    display: grid;
    min-width: 100%;
    margin-bottom: ${(props) => props.rowGap && props.rowGap};

    // If columns sizes are not specified, return equal sized columns of 1fr
    grid-template-columns: ${(props) =>
        props.columnSizes ? props.columnSizes : `repeat(${props.numberOfColumns}, 1fr);`};
    -ms-grid-columns: ${(props) =>
        props.columnSizes ? props.columnSizes : `(1fr)[${props.numberOfColumns}];`};
    grid-auto-rows: ${({ rowHeight }) => rowHeight ?? `minmax(2.5rem, auto)`};

    ${({ isFixedHeader }) => isFixedHeader && 'position: sticky; top: 0; z-index: 10;'};
    ${({ isOddIndex }) =>
        `background-color: ${isOddIndex ? colors.neutral.N25 : colors.other.white};`};
    ${({ isHeader }) =>
        isHeader && `background: ${colors.neutral.N50}; color: ${colors.neutral.N400};`};
    ${({ isSelectedRow }) => isSelectedRow && `background-color: ${colors.green.G50};`};
    ${({ isDisabled }) => !isDisabled && `color: ${colors.blue.B500};`};
    ${({ onClick }) => onClick && `cursor: pointer;`};

    :hover {
        ${({ isHeader, isSelectedRow }) =>
            !isHeader && !isSelectedRow && `background-color: ${colors.neutral.N25};`}
    }
`;

const AddItemLine = styled.div`
    background-color: ${colors.blue.B50};
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem;
    border-radius: ${styles.borderRadiusSmall};
`;

const LineText = styled(Text)`
    margin: 0;
`;

type WrapperProps = {
    windowWidth: number;
    tableHeight: string;
    fullBorder?: boolean;
};

type GridProps = {
    columnSizes?: string;
    numberOfColumns: number;
    isDisabled?: boolean;
    isHeader?: boolean;
    isFixedHeader?: boolean;
    rowGap: string;
    columnGap: string;
    rowHeight?: string;
    isFixedColumns?: boolean;
    isOddIndex?: boolean;
    isSelectedRow?: boolean;
};

type BaseT = {
    id: string | number;
};

export type ClickableHeader = {
    text: string;
    onClick: () => void;
};

export type TableHeaders = Record<string, string | JSX.Element | ClickableHeader>;

type Props<T> = {
    'data-testid'?: string;
    checkIfDisabled?: (row: T) => boolean;
    checkIfSelected?: (row: T) => boolean;
    onRowClick?: (row: T | Partial<T>) => void;
    headers?: TableHeaders;
    isFixedHeader?: boolean;
    rows: T[];
    fixedStartColumnsNumber?: number;
    fixedEndColumnsNumber?: number;
    columnSizes?: string;
    rowHeight?: string;
    fullBorder?: boolean;
    rowGap?: string;
    columnGap?: string;
    style?: React.CSSProperties;
    renderCellMap: RenderCellMapType<T | Partial<T>>;
    shouldCapitalize?: boolean;
    alternateBackgroundColors?: boolean;
    tableHeight?: string;
    hasAddRow?: boolean;
    addRowText?: string;
    renderAddRow?: RenderCellMapType<T | Partial<T>>;
    onAddRowClick?: () => void;
};

const Table = <B extends BaseT>({
    checkIfDisabled,
    checkIfSelected,
    onRowClick,
    headers,
    isFixedHeader = true,
    rows = [],
    fixedStartColumnsNumber = 0,
    fixedEndColumnsNumber = 0,
    columnSizes,
    rowGap = '0',
    columnGap = '1.5rem',
    rowHeight,
    fullBorder = false,
    renderCellMap,
    style,
    shouldCapitalize = false,
    alternateBackgroundColors = false,
    tableHeight = 'fit-content',
    hasAddRow = false,
    addRowText = '',
    onAddRowClick,
    ...props
}: Props<B>) => {
    const [columnSizesList, setColumnSizesList] = useState<number[]>([]);

    const rowRef = useRef<HTMLDivElement>(null);
    const rowsRef = useRef(rows);

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (rowRef.current?.childNodes) {
            const elementsList = rowRef.current.childNodes;
            const widthList = [];
            for (let i = 0; i < elementsList.length; i++) {
                const element = elementsList[i] as HTMLElement;
                widthList.push(element.offsetWidth);
            }
            setColumnSizesList(widthList);
        }
    }, [rowsRef, rowRef, width, columnSizes]);

    const headerKeys = headers && Object.keys(headers);
    const numberOfColumns = headerKeys !== undefined ? headerKeys.length : 0;

    return (
        <Wrapper
            style={style}
            data-testid={props['data-testid'] ?? 'table'}
            windowWidth={width}
            tableHeight={tableHeight}
            fullBorder={fullBorder}
        >
            {/* Load header if there is one  */}
            {headers && (
                <Grid
                    ref={rowRef}
                    numberOfColumns={numberOfColumns}
                    columnSizes={columnSizes}
                    rowGap={rowGap}
                    columnGap={columnGap}
                    isFixedHeader={isFixedHeader}
                    isFixedColumns={Boolean(fixedStartColumnsNumber || fixedEndColumnsNumber)}
                    isHeader
                >
                    <TableRow
                        renderCellMap={renderCellMap}
                        headerKeys={headerKeys}
                        values={headers}
                        fixedStartColumnsNumber={fixedStartColumnsNumber}
                        fixedEndColumnsNumber={fixedEndColumnsNumber}
                        numberOfColumns={numberOfColumns}
                        columnSizesList={columnSizesList}
                        fullBorder={fullBorder}
                        isHeader
                    />
                </Grid>
            )}
            {rows.map((row, index) => (
                <Grid
                    key={`container-${row.id}`}
                    rowHeight={rowHeight}
                    numberOfColumns={
                        numberOfColumns > 0 ? numberOfColumns : Object.keys(row).length
                    }
                    columnSizes={columnSizes}
                    isDisabled={checkIfDisabled?.(row)}
                    rowGap={rowGap}
                    columnGap={columnGap}
                    onClick={
                        onRowClick && !checkIfDisabled?.(row) ? () => onRowClick(row) : undefined
                    }
                    isFixedColumns={Boolean(fixedStartColumnsNumber || fixedEndColumnsNumber)}
                    isOddIndex={alternateBackgroundColors && index % 2 !== 0}
                    isSelectedRow={checkIfSelected?.(row)}
                    data-testid="table-row"
                >
                    <TableRow
                        values={row}
                        renderCellMap={renderCellMap}
                        headerKeys={headerKeys}
                        isDisabled={checkIfDisabled?.(row)}
                        shouldCapitalize={shouldCapitalize}
                        fixedStartColumnsNumber={fixedStartColumnsNumber}
                        fixedEndColumnsNumber={fixedEndColumnsNumber}
                        numberOfColumns={
                            numberOfColumns > 0 ? numberOfColumns : Object.keys(row).length
                        }
                        columnSizesList={columnSizesList}
                        isSelectedRow={checkIfSelected?.(row)}
                        fullBorder={fullBorder}
                        isLastRow={index === rows.length - 1}
                    />
                </Grid>
            ))}
            {hasAddRow && (
                <AddItemLine onClick={onAddRowClick}>
                    <Icon name="AddBox" color={colors.green.G400} style={{ marginRight: '1rem' }} />
                    <LineText type="H500" size="0.875rem" color={colors.neutral.N400}>
                        {addRowText}
                    </LineText>
                </AddItemLine>
            )}
        </Wrapper>
    );
};
export default Table;
