import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format } from 'date-fns';
import styled from 'styled-components';

import CardList from './CardList';
import Table from './Table';
import Text from './Text';
import Tag from './Tag';
import Icon from './Icon';

import { colors } from '../constants/colors';
import { getOrganizationName } from '../slices/organizationSlice';
import type { LabelValue } from '../slices/labelSlice';
import type { Market } from '../slices/marketSlice';
import { CurrencyFormatter } from '../utils/formatters';
import type { ListViewType } from '../slices/common';
import { usePermissionsCheck } from '../hooks/usePermissionsCheck';

const StyledText = styled(Text)`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyleIcon = styled(Icon)`
    transform: translateY(0.1rem);
    margin-right: 0.3rem;
`;

type Props = {
    viewType: ListViewType;
    markets: Market[];
    locationState?: {
        organizationId?: string;
        goBackTo?: string;
    };
    onHeaderClick?: (orderBy: string) => void;
};

const MarketList: React.FunctionComponent<Props> = ({
    viewType,
    markets,
    locationState,
    onHeaderClick,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { operationId } = useParams<{ operationId: string }>();

    const hasRightToReadEngagements = usePermissionsCheck([
        { code: 'ENGAGEMENTS_READ', operationId },
    ]);

    const headers = {
        label: { text: t('market.list.marketName'), onClick: () => onHeaderClick?.('label') },
        externalMarketNumberWithSliceCode: t('market.list.externalMarketNumberWithSliceCode'),
        notification: {
            text: t('market.list.notification'),
            onClick: () => onHeaderClick?.('notificationDate'),
        },
        startDate: t('market.list.startDate'),
        endDate: t('market.list.endDate'),
        amountHtNotified: t('market.list.amountHtNotified'),
        echeancier: t('market.list.echeancier'),
        organism: t('market.list.organism'),
    };

    const renderCellMap = {
        externalMarketNumberWithSliceCode: (marketData: Market | Partial<Market>) =>
            marketData.sliceCode
                ? `${marketData.externalNumber}-${marketData.sliceCode}`
                : String(marketData.externalNumber) || `-`,
        notification: (marketData: Market | Partial<Market>) =>
            marketData.firstEngagementSortedByNotificationDate ?? `-`,
        startDate: (marketData: Market | Partial<Market>) => marketData.plannedStartDate ?? `-`,
        endDate: (marketData: Market | Partial<Market>) => marketData.plannedEndDate ?? `-`,
        amountHtNotified: (marketData: Market | Partial<Market>) =>
            (marketData.initialAmountHt && CurrencyFormatter.format(marketData.initialAmountHt)) ||
            `-`,
        echeancier: (marketData: Market | Partial<Market>) =>
            marketData.hasOutdatedEcheancierEngagements ? (
                <Icon
                    name="Warning"
                    color={colors.yellow.Y400}
                    width="1.1rem"
                    tooltip={t('market.list.outdatedEcheanciersEngagement')}
                />
            ) : null,
        organism: (marketData: Market | Partial<Market>) =>
            getOrganizationName(marketData.marketHolder) ?? `-`,
    };

    const mapDataToCardProps = (marketData: Market | Partial<Market>) => ({
        headerTitle: String(marketData.label),
        isTooltipOneLine: true,
        showIcon: marketData.hasOutdatedEcheancierEngagements,
        bodyData: {
            [String(headers.externalMarketNumberWithSliceCode)]: marketData.sliceCode
                ? `${marketData.externalNumber}-${marketData.sliceCode}`
                : String(marketData.externalNumber),
            [String(headers.notification)]:
                marketData.firstEngagementSortedByNotificationDate ?? `-`,
            [String(headers.startDate)]: marketData.plannedStartDate ?? `-`,
            [String(headers.endDate)]: marketData.plannedEndDate ?? `-`,
            [String(headers.amountHtNotified)]: marketData.initialAmountHt ? (
                <StyledText
                    data-testid={`bodyItem${headers.amountHtNotified}-${marketData.initialAmountHt}`}
                    isNumeral
                >
                    {CurrencyFormatter.format(marketData.initialAmountHt)}
                </StyledText>
            ) : (
                `-`
            ),
            [String(t('market.list.lastModification'))]: marketData.updatedAt
                ? `${t('market.list.lastUpdatedBy', {
                      date: format(
                          new Date(marketData.updatedAt.replace(/-/g, '/')),
                          // replace dash by slash for Safari support (https://stackoverflow.com/questions/4310953/invalid-date-in-safari)
                          'dd/MM/yyyy',
                      ),
                      userFullName: `${marketData.updatedByUser?.profile.firstName} ${marketData.updatedByUser?.profile.lastName}`,
                  })}  `
                : '-',
            [String(headers.echeancier)]: (
                <>
                    {marketData.hasOutdatedEcheancierEngagements && (
                        <StyleIcon name="Warning" color={colors.yellow.Y400} width="1.1rem" />
                    )}
                    <StyledText
                        data-testid={`bodyItem${headers.echeancier}-${marketData.hasOutdatedEcheancierEngagements}`}
                    >
                        {marketData.hasOutdatedEcheancierEngagements
                            ? t('market.list.echeanciersEngagementNeedUpdate')
                            : t('market.list.upToDateEcheanciersEngagement')}
                    </StyledText>
                </>
            ),
        },
        headerTrailingComponent: (
            <Text style={{ marginBottom: '0' }} color={colors.neutral.N500}>
                {getOrganizationName(marketData.marketHolder)}
            </Text>
        ),
        footerComponent: (
            <>
                <Text style={{ marginBottom: '0' }} color={colors.neutral.N300}>
                    {String(t('market.list.labels'))}:&nbsp;
                </Text>
                {marketData.labelValues?.map((labelValue: LabelValue) => (
                    <Tag key={labelValue.id} text={labelValue.value} rounded />
                ))}
            </>
        ),
    });

    const handleClick = (row: Market | Partial<Market>) => {
        if (hasRightToReadEngagements) {
            navigate(`/operations/${operationId}/markets/${row.id}/engagements`, {
                state: locationState,
            });
        } else {
            navigate(`/operations/${operationId}/markets/${row.id}`, {
                state: locationState,
            });
        }
    };

    return (
        <>
            {viewType === 'card' && (
                <CardList
                    data={markets}
                    mapDataToCardProps={mapDataToCardProps}
                    onCardClick={handleClick}
                    data-testid="operationCardList"
                />
            )}
            {viewType === 'table' && (
                <Table
                    headers={headers}
                    rows={markets}
                    columnSizes="1.5fr 1.3fr 1.4fr 1.2fr 1.2fr 1.5fr 1.2fr 2fr"
                    renderCellMap={renderCellMap}
                    onRowClick={handleClick}
                    data-testid="operationTableList"
                />
            )}
        </>
    );
};

export default MarketList;
