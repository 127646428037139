import type { CSSProperties } from 'react';
import React from 'react';
import type { StyledIcon } from 'styled-icons/types';
import { ViewList } from '@styled-icons/material-rounded/ViewList';
import { ViewModule } from '@styled-icons/material-rounded/ViewModule';
import { Visibility } from '@styled-icons/material-rounded/Visibility';
import { VisibilityOff } from '@styled-icons/material-rounded/VisibilityOff';
import { GroupAdd } from '@styled-icons/material-rounded/GroupAdd';
import { Group } from '@styled-icons/material-rounded/Group';
import { Home } from '@styled-icons/heroicons-solid/Home';
import { Home as HomeOutlined } from '@styled-icons/heroicons-outline/Home';
import { Info } from '@styled-icons/material-rounded/Info';
import { KeyboardBackspace } from '@styled-icons/material-rounded/KeyboardBackspace';
import { LibraryAdd } from '@styled-icons/material-rounded/LibraryAdd';
import { LibraryBooks } from '@styled-icons/material-rounded/LibraryBooks';
import { MailOutline } from '@styled-icons/material-rounded/MailOutline';
import { NotificationsNone } from '@styled-icons/material-rounded/NotificationsNone';
import { Notifications } from '@styled-icons/material-rounded/Notifications';
import { OutlinedFlag } from '@styled-icons/material-rounded/OutlinedFlag';
import { Portrait } from '@styled-icons/material-rounded/Portrait';
import { Refresh } from '@styled-icons/material-rounded/Refresh';
import { ReportProblem } from '@styled-icons/material-rounded/ReportProblem';
import { Search } from '@styled-icons/material-rounded/Search';
import { Settings } from '@styled-icons/material-rounded/Settings';
import { SupervisorAccount } from '@styled-icons/material-rounded/SupervisorAccount';
import { BookmarkBorder } from '@styled-icons/material-rounded/BookmarkBorder';
import { Cached } from '@styled-icons/material-rounded/Cached';
import { Cancel } from '@styled-icons/material-rounded/Cancel';
import { CheckCircleOutline } from '@styled-icons/material-rounded/CheckCircleOutline';
import { CheckCircle } from '@styled-icons/material-rounded/CheckCircle';
import { Contacts } from '@styled-icons/material-rounded/Contacts';
import { AddBox } from '@styled-icons/material-rounded/AddBox';
import { Add } from '@styled-icons/material-rounded/Add';
import { Remove } from '@styled-icons/material-rounded/Remove';
import { RemoveCircleOutline } from '@styled-icons/material-rounded/RemoveCircleOutline';
import { ArrowBackIos } from '@styled-icons/material-rounded/ArrowBackIos';
import { ArrowBack } from '@styled-icons/material-rounded/ArrowBack';
import { ArrowForwardIos } from '@styled-icons/material-rounded/ArrowForwardIos';
import { ArrowForward } from '@styled-icons/material-rounded/ArrowForward';
import { AttachFile } from '@styled-icons/material-rounded/AttachFile';
import { Bookmark } from '@styled-icons/material-rounded/Bookmark';
import { Building } from '@styled-icons/fa-regular/Building';
import { Dashboard } from '@styled-icons/material-rounded/Dashboard';
import { Dashboard as DashboardOutlined } from '@styled-icons/material-outlined/Dashboard';
import { DeleteOutline } from '@styled-icons/material-rounded/DeleteOutline';
import { Delete } from '@styled-icons/material-rounded/Delete';
import { Description } from '@styled-icons/material-rounded/Description';
import { Description as DescriptionOutline } from '@styled-icons/material-outlined/Description';
import { FilterList } from '@styled-icons/material-rounded/FilterList';
import { Email } from '@styled-icons/material-rounded/Email';
import { Edit } from '@styled-icons/material-rounded/Edit';
import { ErrorOutline } from '@styled-icons/material-rounded/ErrorOutline';
import { Error } from '@styled-icons/material-rounded/Error';
import { Flag } from '@styled-icons/material-rounded/Flag';
import { FlashOn } from '@styled-icons/material-rounded/FlashOn';
import { FolderOpen } from '@styled-icons/material-rounded/FolderOpen';
import { Folder } from '@styled-icons/material-rounded/Folder';
import { GetApp } from '@styled-icons/material-rounded/GetApp';
import { HighlightOff } from '@styled-icons/material-rounded/HighlightOff';
import { PermIdentity } from '@styled-icons/material-rounded/PermIdentity';
import { DragIndicator } from '@styled-icons/material-rounded/DragIndicator';
import { ListAlt } from '@styled-icons/material-rounded/ListAlt';
import { ImportExport } from '@styled-icons/material-rounded/ImportExport';
import { Comment } from '@styled-icons/material-rounded/Comment';
import { ClearAll } from '@styled-icons/material-rounded/ClearAll';
import { Chat } from '@styled-icons/material-rounded/Chat';
import { ChatBubbleOutline } from '@styled-icons/material-rounded/ChatBubbleOutline';
import { Person } from '@styled-icons/material-rounded/Person';
import { Close } from '@styled-icons/material-rounded/Close';
import { Work } from '@styled-icons/material-rounded/Work';
import { WorkOutline } from '@styled-icons/material/WorkOutline';
import { MoreHoriz } from '@styled-icons/material-rounded/MoreHoriz';
import { Sort } from '@styled-icons/material-rounded/Sort';
import { ArrowDropUp } from '@styled-icons/material-rounded/ArrowDropUp';
import { ArrowDropDown } from '@styled-icons/material-rounded/ArrowDropDown';
import { CameraAlt } from '@styled-icons/material-rounded/CameraAlt';
import { AccountBalance } from '@styled-icons/material-rounded/AccountBalance';
import { KeyboardArrowUp } from '@styled-icons/material-rounded/KeyboardArrowUp';
import { KeyboardArrowDown } from '@styled-icons/material-rounded/KeyboardArrowDown';
import { LibraryBooks as LibraryBooksOutlined } from '@styled-icons/material-outlined/LibraryBooks';
import { BarsArrowUp } from '@styled-icons/heroicons-solid/BarsArrowUp';
import { BarsArrowDown } from '@styled-icons/heroicons-solid/BarsArrowDown';
import { Warning } from '@styled-icons/material-rounded/Warning';
import { Topic } from '@styled-icons/material-rounded/Topic';
import { Topic as TopicOutline } from '@styled-icons/material-outlined/Topic';
import { Link } from '@styled-icons/material-rounded/Link';
import { LinkOff } from '@styled-icons/material-rounded/LinkOff';
import { GearFill } from '@styled-icons/bootstrap/GearFill';
import { Minimize } from '@styled-icons/material/Minimize';
import { Done } from '@styled-icons/material/Done';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { ErrorWarning } from '@styled-icons/remix-fill/ErrorWarning';
import { Calculate } from '@styled-icons/material-outlined/Calculate';
import { SwapVert } from '@styled-icons/material-outlined/SwapVert';

import Tooltip from './Tooltip';

type Props = {
    color?: string;
    name:
        | 'Building'
        | 'ViewList'
        | 'ViewModule'
        | 'VisibilityOff'
        | 'Visibility'
        | 'GroupAdd'
        | 'Group'
        | 'Home'
        | 'HomeOutlined'
        | 'Info'
        | 'KeyboardBackspace'
        | 'LibraryAdd'
        | 'LibraryBooks'
        | 'MailOutline'
        | 'NotificationsNone'
        | 'Notifications'
        | 'OutlinedFlag'
        | 'Portrait'
        | 'Refresh'
        | 'ReportProblem'
        | 'Search'
        | 'Settings'
        | 'SupervisorAccount'
        | 'BookmarkBorder'
        | 'Cached'
        | 'Cancel'
        | 'CheckCircleOutline'
        | 'CheckCircle'
        | 'Contacts'
        | 'AddBox'
        | 'Add'
        | 'Remove'
        | 'RemoveCircleOutline'
        | 'ArrowBackIos'
        | 'ArrowBack'
        | 'ArrowForwardIos'
        | 'ArrowForward'
        | 'AttachFile'
        | 'Bookmark'
        | 'Dashboard'
        | 'DashboardOutlined'
        | 'DeleteOutline'
        | 'Delete'
        | 'Description'
        | 'DescriptionOutline'
        | 'FilterList'
        | 'Email'
        | 'Edit'
        | 'ErrorOutline'
        | 'Error'
        | 'Flag'
        | 'FlashOn'
        | 'FolderOpen'
        | 'Folder'
        | 'GetApp'
        | 'HighlightOff'
        | 'PermIdentity'
        | 'DragIndicator'
        | 'ListAlt'
        | 'ImportExport'
        | 'Comment'
        | 'ClearAll'
        | 'Chat'
        | 'ChatBubbleOutline'
        | 'Person'
        | 'Close'
        | 'Work'
        | 'WorkOutline'
        | 'MoreHoriz'
        | 'Sort'
        | 'ArrowDropUp'
        | 'ArrowDropDown'
        | 'CameraAlt'
        | 'AccountBalance'
        | 'KeyboardArrowUp'
        | 'KeyboardArrowDown'
        | 'LibraryBooksOutlined'
        | 'BarsArrowUp'
        | 'BarsArrowDown'
        | 'Warning'
        | 'Topic'
        | 'TopicOutline'
        | 'Link'
        | 'LinkOff'
        | 'GearFill'
        | 'Minimize'
        | 'Done'
        | 'ContentCopy'
        | 'ErrorWarning'
        | 'Calculate'
        | 'SwapVert';
    onClick?: (event: React.MouseEvent<SVGElement>) => void;
    tooltip?: string;
    width?: string;
    style?: CSSProperties;
    'data-testid'?: string;
};

export type IconName = Props['name'];

const Icon: React.FC<Props> = ({ color, name, onClick, width, style, tooltip, ...rest }) => {
    const customStyle: CSSProperties = {
        color,
        width: width ?? '1.5rem',
        height: width ?? '1.5rem',
        ...style,
    };
    let CustomIcon: StyledIcon;
    switch (name) {
        case 'Building':
            CustomIcon = Building;
            break;
        case 'ViewList':
            CustomIcon = ViewList;
            break;
        case 'ViewModule':
            CustomIcon = ViewModule;
            break;
        case 'VisibilityOff':
            CustomIcon = VisibilityOff;
            break;
        case 'Visibility':
            CustomIcon = Visibility;
            break;
        case 'GroupAdd':
            CustomIcon = GroupAdd;
            break;
        case 'Group':
            CustomIcon = Group;
            break;
        case 'Home':
            CustomIcon = Home;
            break;
        case 'HomeOutlined':
            CustomIcon = HomeOutlined;
            break;
        case 'Info':
            CustomIcon = Info;
            break;
        case 'KeyboardBackspace':
            CustomIcon = KeyboardBackspace;
            break;
        case 'LibraryAdd':
            CustomIcon = LibraryAdd;
            break;
        case 'LibraryBooks':
            CustomIcon = LibraryBooks;
            break;
        case 'MailOutline':
            CustomIcon = MailOutline;
            break;
        case 'NotificationsNone':
            CustomIcon = NotificationsNone;
            break;
        case 'Notifications':
            CustomIcon = Notifications;
            break;
        case 'OutlinedFlag':
            CustomIcon = OutlinedFlag;
            break;
        case 'Portrait':
            CustomIcon = Portrait;
            break;
        case 'Refresh':
            CustomIcon = Refresh;
            break;
        case 'ReportProblem':
            CustomIcon = ReportProblem;
            break;
        case 'Search':
            CustomIcon = Search;
            break;
        case 'Settings':
            CustomIcon = Settings;
            break;
        case 'SupervisorAccount':
            CustomIcon = SupervisorAccount;
            break;
        case 'BookmarkBorder':
            CustomIcon = BookmarkBorder;
            break;
        case 'Cached':
            CustomIcon = Cached;
            break;
        case 'Cancel':
            CustomIcon = Cancel;
            break;
        case 'CheckCircleOutline':
            CustomIcon = CheckCircleOutline;
            break;
        case 'CheckCircle':
            CustomIcon = CheckCircle;
            break;
        case 'Contacts':
            CustomIcon = Contacts;
            break;
        case 'AddBox':
            CustomIcon = AddBox;
            break;
        case 'Add':
            CustomIcon = Add;
            break;
        case 'Remove':
            CustomIcon = Remove;
            break;
        case 'RemoveCircleOutline':
            CustomIcon = RemoveCircleOutline;
            break;
        case 'ArrowBackIos':
            CustomIcon = ArrowBackIos;
            break;
        case 'ArrowBack':
            CustomIcon = ArrowBack;
            break;
        case 'ArrowForwardIos':
            CustomIcon = ArrowForwardIos;
            break;
        case 'ArrowForward':
            CustomIcon = ArrowForward;
            break;
        case 'AttachFile':
            CustomIcon = AttachFile;
            break;
        case 'Bookmark':
            CustomIcon = Bookmark;
            break;
        case 'Dashboard':
            CustomIcon = Dashboard;
            break;
        case 'DashboardOutlined':
            CustomIcon = DashboardOutlined;
            break;
        case 'DeleteOutline':
            CustomIcon = DeleteOutline;
            break;
        case 'Delete':
            CustomIcon = Delete;
            break;
        case 'Description':
            CustomIcon = Description;
            break;
        case 'DescriptionOutline':
            CustomIcon = DescriptionOutline;
            break;
        case 'FilterList':
            CustomIcon = FilterList;
            break;
        case 'Email':
            CustomIcon = Email;
            break;
        case 'Edit':
            CustomIcon = Edit;
            break;
        case 'ErrorOutline':
            CustomIcon = ErrorOutline;
            break;
        case 'Error':
            CustomIcon = Error;
            break;
        case 'Flag':
            CustomIcon = Flag;
            break;
        case 'FlashOn':
            CustomIcon = FlashOn;
            break;
        case 'FolderOpen':
            CustomIcon = FolderOpen;
            break;
        case 'Folder':
            CustomIcon = Folder;
            break;
        case 'GetApp':
            CustomIcon = GetApp;
            break;
        case 'HighlightOff':
            CustomIcon = HighlightOff;
            break;
        case 'PermIdentity':
            CustomIcon = PermIdentity;
            break;
        case 'DragIndicator':
            CustomIcon = DragIndicator;
            break;
        case 'ListAlt':
            CustomIcon = ListAlt;
            break;
        case 'ImportExport':
            CustomIcon = ImportExport;
            break;
        case 'Comment':
            CustomIcon = Comment;
            break;
        case 'ClearAll':
            CustomIcon = ClearAll;
            break;
        case 'Chat':
            CustomIcon = Chat;
            break;
        case 'ChatBubbleOutline':
            CustomIcon = ChatBubbleOutline;
            break;
        case 'Person':
            CustomIcon = Person;
            break;
        case 'Close':
            CustomIcon = Close;
            break;
        case 'Work':
            CustomIcon = Work;
            break;
        case 'WorkOutline':
            CustomIcon = WorkOutline;
            break;
        case 'MoreHoriz':
            CustomIcon = MoreHoriz;
            break;
        case 'Sort':
            CustomIcon = Sort;
            break;
        case 'ArrowDropUp':
            CustomIcon = ArrowDropUp;
            break;
        case 'ArrowDropDown':
            CustomIcon = ArrowDropDown;
            break;
        case 'CameraAlt':
            CustomIcon = CameraAlt;
            break;
        case 'AccountBalance':
            CustomIcon = AccountBalance;
            break;
        case 'KeyboardArrowUp':
            CustomIcon = KeyboardArrowUp;
            break;
        case 'KeyboardArrowDown':
            CustomIcon = KeyboardArrowDown;
            break;
        case 'LibraryBooksOutlined':
            CustomIcon = LibraryBooksOutlined;
            break;
        case 'BarsArrowUp':
            CustomIcon = BarsArrowUp;
            break;
        case 'BarsArrowDown':
            CustomIcon = BarsArrowDown;
            break;
        case 'Warning':
            CustomIcon = Warning;
            break;
        case 'Topic':
            CustomIcon = Topic;
            break;
        case 'TopicOutline':
            CustomIcon = TopicOutline;
            break;
        case 'Link':
            CustomIcon = Link;
            break;
        case 'LinkOff':
            CustomIcon = LinkOff;
            break;
        case 'GearFill':
            CustomIcon = GearFill;
            break;
        case 'Minimize':
            CustomIcon = Minimize;
            break;
        case 'Done':
            CustomIcon = Done;
            break;
        case 'ContentCopy':
            CustomIcon = ContentCopy;
            break;
        case 'ErrorWarning':
            CustomIcon = ErrorWarning;
            break;
        case 'Calculate':
            CustomIcon = Calculate;
            break;
        case 'SwapVert':
            CustomIcon = SwapVert;
            break;
        default:
            console.error(`No icon for name: ${name}.`);
            return null;
    }

    const content = <CustomIcon onClick={onClick} color={color} style={customStyle} {...rest} />;

    if (tooltip) {
        return <Tooltip content={tooltip}>{content}</Tooltip>;
    }

    return content;
};

export default Icon;
